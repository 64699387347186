// Libraries imports
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const QuestionModal = ({
  showModal,
  handleCloseModal,
  questoes,
  questoesRespondidas,
  handleSelecionarAlternativa,
  handleSubmitModal,
  handleAlterar,
  modalVisualizacao,
  formularioEnviado,
  selectedContentId,
  treinamentoId,
  currentQuestionId,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [respostaUsuario, setRespostaUsuario] = useState(null);

  useEffect(() => {
    if (showModal && currentQuestionId) {
      const index = questoes.findIndex((q) => q.id === currentQuestionId.id);
      setCurrentIndex(index);
    }
  }, [showModal, currentQuestionId, questoes]);

  const currentQuestion = questoes[currentIndex];

  useEffect(() => {
    if (currentQuestion) {
      const resposta =
        questoesRespondidas[treinamentoId]?.[selectedContentId]?.[
          currentQuestion.id
        ]?.resposta || null;
      setRespostaUsuario(resposta);
    }
  }, [
    currentIndex,
    currentQuestion,
    questoesRespondidas,
    selectedContentId,
    treinamentoId,
  ]);

  const handleNext = () => {
    if (currentIndex < questoes.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleResposta = (alternativa) => {
    handleSelecionarAlternativa(alternativa);
    setRespostaUsuario(alternativa.order);
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {currentQuestion && (
            <span
              dangerouslySetInnerHTML={{ __html: currentQuestion.enunciado }}
            />
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentQuestion &&
          currentQuestion.alternativas.map((alternativa, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
              <Button
                variant={
                  respostaUsuario === alternativa.order
                    ? "primary"
                    : "outline-primary"
                }
                onClick={() => handleResposta(alternativa)}
                className={`me-2 mb-4 ${
                  modalVisualizacao[treinamentoId]?.[selectedContentId]?.[
                    currentQuestion?.id
                  ] &&
                  questoesRespondidas[treinamentoId]?.[selectedContentId]?.[
                    currentQuestion.id
                  ]?.correta &&
                  alternativa.order ===
                    questoesRespondidas[treinamentoId][selectedContentId]?.[
                      currentQuestion.id
                    ].resposta
                }`}
                disabled={
                  modalVisualizacao[treinamentoId]?.[selectedContentId]?.[
                    currentQuestion?.id
                  ]
                }
              >
                {alternativa.order}
              </Button>
              <span
                dangerouslySetInnerHTML={{ __html: alternativa.name }}
                className="ml-2 mb-4"
              />
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <div className="mr-auto">
          <Button variant="secondary" onClick={handleCloseModal}>
            Fechar
          </Button>
        </div>
        <div className="ml-auto">
          {currentIndex > 0 && (
            <Button
              variant="secondary"
              onClick={handlePrevious}
              className="mr-2"
            >
              Anterior
            </Button>
          )}
          {currentIndex < questoes.length - 1 && (
            <Button variant="primary" onClick={handleNext} className="mr-2">
              Próxima
            </Button>
          )}

          {!modalVisualizacao[treinamentoId]?.[selectedContentId]?.[
            currentQuestion?.id
          ] ? (
            <Button
              variant="primary"
              onClick={() => handleSubmitModal(currentQuestion?.id)}
              disabled={respostaUsuario === null}
            >
              Responder
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={handleAlterar}
              disabled={formularioEnviado[treinamentoId]?.[selectedContentId]}
            >
              Alterar
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default QuestionModal;
