// Library imports
import axios from "axios";

// Utils imports
import { URL } from "../variables";

const defaultHeaders = (token) => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
});

const handleResponse = (response) => {
  const resp = response.data;

  if (resp.message === "Unauthenticated.") {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return;
  }

  if (resp.errors || resp.error) {
    let errors = Object.values(resp.errors || resp.error);
    let erro = errors.join("\n");
    throw new Error(erro);
  }

  return resp;
};

const handleError = (error) => {
  if (error.response) {
    throw new Error(
      error.response.data.message || "Network response was not ok"
    );
  } else if (error.request) {
    throw new Error("No response received from the server");
  } else {
    throw new Error("An error occurred during the request setup");
  }
};

export const get = async (endpoint, token) => {
  try {
    const response = await axios.get(`${URL}${endpoint}`, {
      headers: defaultHeaders(token),
    });
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const post = async (endpoint, data, token) => {
  try {
    const response = await axios.post(`${URL}${endpoint}`, data, {
      headers: defaultHeaders(token),
    });
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const put = async (endpoint, data, token) => {
  try {
    const response = await axios.put(`${URL}${endpoint}`, data, {
      headers: defaultHeaders(token),
    });
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const del = async (endpoint, token) => {
  try {
    const response = await axios.delete(`${URL}${endpoint}`, {
      headers: defaultHeaders(token),
    });
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};
