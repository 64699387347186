// Libraries imports
import React, { useState, useEffect } from "react";
import { Spinner, Button } from "react-bootstrap";
import axios from "axios";

// Utils imports
import { URL } from "../../../../variables";
import { get } from "../../../../services/api";

const ModuleList = ({
  modulos,
  expandedModulo,
  toggleExpand,
  conteudos,
  selectedContentId,
  getData,
  isConteudoConcluido,
  loadingModulo,
  permissaoProximoConteudo,
  idTreinamento,
  gerarCertificado,
  nomeTreinamento,
  token,
  conteudosModulosConcluidos,
  modulosConcluidos,
}) => {
  const [loadingCertificado, setLoadingCertificado] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    get_user();
  }, []);

  const get_user = async () => {
    try {
      const resp = await get("api/fiscal/get_user/", token);
      setUser(resp.user);
    } catch (err) {
      console.error(err);
      setTimeout(get_user, 5000);
    }
  };

  const handleGenerateCertificate = async () => {
    if (!user) {
      console.error("Usuário não encontrado");
      return;
    }

    setLoadingCertificado(true);
    try {
      const response = await axios.post(
        `${URL}/api/generate-certificate`,
        {
          idTreinamento,
          nomeTreinamento,
          userName: user.name,
        },
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "certificado.pdf");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Erro ao gerar certificado:", error);
    } finally {
      setLoadingCertificado(false);
    }
  };

  return (
    <div className="list-group">
      {modulos.map((modulo, index) => {
        const totalConteudos = conteudos[modulo.id]?.length || 0;
        const conteudosConcluidos = conteudos[modulo.id]?.filter((conteudo) =>
          isConteudoConcluido(conteudo.id)
        ).length;
        const moduloConcluido = modulosConcluidos[modulo.id];
        const statusMensagem = moduloConcluido
          ? "Módulo Concluído"
          : "Módulo Pendente";

        return (
          <div key={index}>
            <a
              className={`list-group-item list-group-item-action ${
                expandedModulo === modulo.id ? "active" : ""
              }`}
              onClick={() => toggleExpand(modulo.id)}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor:
                  expandedModulo === modulo.id ? "#f7f7f7" : "#ffffff",
                borderBottom: "1px solid #e6e6e6",
                color: "#333333",
              }}
            >
              <div>
                <h5 style={{ margin: 0 }}>
                  Seção {index + 1}: {modulo.name}
                </h5>
                <p style={{ margin: 0 }}>{modulo.description}</p>
                {expandedModulo === modulo.id ? (
                  loadingModulo !== modulo.id && (
                    <small style={{ color: "#999999" }}>
                      {`${conteudosConcluidos}/${totalConteudos}`}
                    </small>
                  )
                ) : (
                  <small style={{ color: "#999999" }}>{statusMensagem}</small>
                )}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <i
                  className={`mdi ${
                    expandedModulo === modulo.id
                      ? "mdi-chevron-up"
                      : "mdi-chevron-down"
                  }`}
                  title="Expandir/Colapsar"
                  style={{ color: "#666666" }}
                ></i>
                {loadingModulo === modulo.id && (
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    className="ms-2"
                    style={{ color: "#28a745" }}
                  />
                )}
              </div>
            </a>
            {expandedModulo === modulo.id && (
              <div>
                {conteudos[modulo.id]?.map((conteudo, idx) => (
                  <a
                    key={idx}
                    className={`list-group-item list-group-item-action content-item d-flex align-items-center ${
                      selectedContentId === conteudo.id
                        ? "content-item-active"
                        : ""
                    }`}
                    onClick={() => {
                      if (
                        permissaoProximoConteudo ||
                        isConteudoConcluido(conteudo.id) ||
                        conteudosModulosConcluidos?.[expandedModulo]?.[
                          selectedContentId
                        ]
                      ) {
                        getData(conteudo.id);
                      } else {
                        alert(
                          "Você precisa responder todas as perguntas corretamente para prosseguir!"
                        );
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        selectedContentId === conteudo.id
                          ? "#f7f7f7"
                          : "#ffffff",
                      borderBottom: "1px solid #e6e6e6",
                      color: "#333333",
                      padding: "10px 15px",
                    }}
                  >
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        border: "1px solid #cccccc",
                        backgroundColor: isConteudoConcluido(conteudo.id)
                          ? "#28a745"
                          : "#ffffff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "10px",
                      }}
                    >
                      {isConteudoConcluido(conteudo.id) && (
                        <i className="mdi mdi-check text-white"></i>
                      )}
                    </div>
                    <span>{`${idx + 1}. ${conteudo.name}`}</span>
                  </a>
                ))}
              </div>
            )}
          </div>
        );
      })}
      <div className="mt-3 text-center">
        {user && gerarCertificado[idTreinamento] && (
          <Button
            variant="primary"
            onClick={handleGenerateCertificate}
            disabled={loadingCertificado}
          >
            {loadingCertificado ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Gerar certificado"
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ModuleList;
