// Libraries imports
import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Components imports
import ModuleList from "../ShowTreinamentos/Components/ModuleList";
import LoadingSkeleton from "../ShowTreinamentos/Components/LoadingSkeleton";
import QuestionModal from "../ShowTreinamentos/Components/QuestionModal";
import VideoPlayer from "../ShowTreinamentos/Components/VideoPlayer";
import QuestionsTable from "../ShowTreinamentos/Components/QuestionsTable";
import AnswerModal from "./Components/AnswerModal";

// Utils imports
import { get } from "../../../services/api";
import { logout } from "../../../actions/AppActions";

// CSS imports
import "../Css/ShowTreinamentos.css";
import "react-loading-skeleton/dist/skeleton.css";

class ShowTreinamentos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: "",
      descricao: "",
      modulos: [],
      loading: true,
      expandedModulo: null,
      conteudos: {},
      questoesAll: [],
      name: "",
      file: "",
      videoFileName: "",
      thumbnail: "",
      redirect: false,
      alternativasPorQuestao: {},
      selectedContentId: null,
      watchedTime: 0,
      loadingModulo: null,
      showModal: false,
      showAnswerModal: false,
      currentQuestion: null,
      respostaCorreta: null,
      respostaUsuario: null,
      respostaCorretaMsg: null,
      respostaIncorretaMsg: null,
      totalDeAcertos: 0,
      questoesRespondidas: {},
      modalVisualizacao: false,
      permissaoProximoConteudo: false,
      conteudosConcluidos: {},
      conteudosModulosConcluidos: {},
      videoWatched: false,
      formularioEnviado: {},
      quantidadeModulosConcluidosUpdated: {},
      modulosConcluidos: {},
      gerarCertificado: {},
    };
    this.videoRef = React.createRef();
    this.modalRef = React.createRef();
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.getTreinamentos(id);

    this.loadStateFromLocalStorage("modulosConcluidos");
    this.loadStateFromLocalStorage("conteudosModulosConcluidos");
    this.loadStateFromLocalStorage("quantidadeModulosConcluidosUpdated");

    this.setState({
      conteudosConcluidos: this.loadFromLocalStorage("conteudosConcluidos", {}),
      questoesRespondidas: this.loadFromLocalStorage("questoesRespondidas", {}),
      formularioEnviado: this.loadFromLocalStorage("formularioEnviado", {}),
      modalVisualizacao: this.loadFromLocalStorage("modalVisualizacao", {}),
    });

    window.addEventListener("beforeunload", this.handleUnload);
    window.addEventListener("unload", this.handleUnload);
  }

  componentDidUpdate(prevProps, prevState) {
    this.saveToLocalStorageIfChanged(
      prevState.conteudosConcluidos,
      this.state.conteudosConcluidos,
      "conteudosConcluidos"
    );
    this.saveToLocalStorageIfChanged(
      prevState.questoesRespondidas,
      this.state.questoesRespondidas,
      "questoesRespondidas"
    );
    this.saveToLocalStorageIfChanged(
      prevState.formularioEnviado,
      this.state.formularioEnviado,
      "formularioEnviado"
    );
    this.saveToLocalStorageIfChanged(
      prevState.modalVisualizacao,
      this.state.modalVisualizacao,
      "modalVisualizacao"
    );
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleUnload);
    window.removeEventListener("unload", this.handleUnload);
  }

  loadStateFromLocalStorage(key) {
    const data = JSON.parse(localStorage.getItem(key));
    if (data) {
      this.setState({ [key]: data });
    }
  }

  loadFromLocalStorage(key, defaultValue) {
    return JSON.parse(localStorage.getItem(key)) || defaultValue;
  }

  saveToLocalStorageIfChanged(prevValue, currentValue, key) {
    if (prevValue !== currentValue) {
      localStorage.setItem(key, JSON.stringify(currentValue));
    }
  }

  handleUnload = () => {
    const { conteudosConcluidos } = this.state;
    const id = this.props.match.params.id;

    if (this.isTreinamentoConcluido(conteudosConcluidos[id])) {
      this.setTreinamentoStatus(id, "treinamentoConcluido");
    } else if (this.isTreinamentoNovo(conteudosConcluidos[id])) {
      this.setTreinamentoStatus(id, "treinamentoNovo");
    } else {
      this.setTreinamentoStatus(id, "treinamentoEmAndamento");
    }
  };

  isTreinamentoConcluido(conteudosConcluidos) {
    return (
      Object.keys(conteudosConcluidos).length === this.state.modulos.length &&
      Object.values(conteudosConcluidos).every((modulo) =>
        Object.values(modulo).every((conteudo) => conteudo === true)
      )
    );
  }

  isTreinamentoNovo(conteudosConcluidos) {
    return Object.values(conteudosConcluidos).every((modulo) =>
      Object.values(modulo).every((conteudo) => conteudo === false)
    );
  }

  setTreinamentoStatus(id, status) {
    const statusKeys = [
      "treinamentoConcluido",
      "treinamentoEmAndamento",
      "treinamentoNovo",
    ];
    statusKeys.forEach((key) => localStorage.removeItem(`${key}_${id}`));
    localStorage.setItem(`${status}_${id}`, "true");
  }

  async getTreinamentos(id) {
    this.setState({ loading: true });
    try {
      const data = await get(
        `api/treinamento/get_training/${id}`,
        this.props.token
      );
      const { nome, descricao, modulos } = data.data;

      this.setState({ nome, descricao, modulos });

      const ultimoModuloId = localStorage.getItem(`ultimoModuloId_${id}`);
      const ultimoConteudoId = localStorage.getItem(
        `ultimoConteudoId_${id}_${ultimoModuloId}`
      );

      if (ultimoModuloId && ultimoConteudoId) {
        await this.carregarUltimoModuloEConteudo(
          ultimoModuloId,
          ultimoConteudoId
        );
      } else if (modulos.length > 0) {
        await this.carregarPrimeiroModuloEConteudo(modulos[0].id, id);
      }
    } catch (error) {
      this.setState({ errorMsg: error.message || "Erro ao buscar o módulo" });
    } finally {
      this.setState({ loading: false });
    }
  }

  async carregarUltimoModuloEConteudo(ultimoModuloId, ultimoConteudoId) {
    this.setState({ expandedModulo: parseInt(ultimoModuloId, 10) });
    await this.getModulo(parseInt(ultimoModuloId, 10));
    await this.getData(parseInt(ultimoConteudoId, 10));
  }

  async carregarPrimeiroModuloEConteudo(primeiroModuloId, treinamentoId) {
    this.setState({ expandedModulo: primeiroModuloId });
    await this.getModulo(primeiroModuloId);

    const conteudos = this.state.conteudos[primeiroModuloId];
    if (conteudos && conteudos.length > 0) {
      const primeiroConteudoId = conteudos[0].id;
      await this.getData(primeiroConteudoId);

      localStorage.setItem("ultimoTreinamentoId", treinamentoId);
      localStorage.setItem(`ultimoModuloId_${treinamentoId}`, primeiroModuloId);
      localStorage.setItem(
        `ultimoConteudoId_${treinamentoId}_${primeiroModuloId}`,
        primeiroConteudoId
      );
    }
  }

  async getModulo(id) {
    if (this.state.conteudos[id]) {
      return;
    }

    this.setState({ loadingModulo: id });

    try {
      const data = await get(`api/modulo/get_module/${id}`, this.props.token);

      this.setState((prevState) => ({
        conteudos: {
          ...prevState.conteudos,
          [id]: data.module.conteudos,
        },
        loadingModulo: null,
        expandedModulo: id,
      }));

      if (data.errors) {
        let errors = Object.values(data.errors);
        let errorMsg = errors.join("\n");
        this.setState({ errorMsg });
      }
    } catch (error) {
      this.setState({
        errorMsg: error.message || "Erro ao buscar o conteúdo do módulo",
      });
    }
  }

  async getConteudo(id) {
    try {
      const watchedTime =
        parseFloat(localStorage.getItem(`watchedTime_${id}`)) || 0;

      const data = await get(
        `api/conteudo/get_content/${id}`,
        this.props.token
      );

      const { name, file, thumbnail } = data.data;

      const videoFileName = file.replace("storage/conteudos/", "");

      this.setState({
        name,
        file,
        videoFileName,
        thumbnail,
        watchedTime,
      });

      localStorage.setItem("ultimoConteudoId", id);
      this.setState({ selectedContentId: id });

      if (file.endsWith(".mp4")) {
        this.saveInterval = setInterval(this.saveWatchedTime, 1000);
      }
    } catch (error) {
      this.setState({ redirect: true });
    }
  }

  async getQuestao(id) {
    try {
      const data = await get(
        `api/conteudo/get_questions/${id}`,
        this.props.token
      );

      const responseData = data.data;

      const questoesAll = responseData.map((questao) => {
        const alternativaCorreta = questao.alternativas.find(
          (alternativa) => alternativa.alternativa_correta
        );

        const questaoData = {
          id: questao.id,
          nome: questao.nome,
          enunciado: questao.enunciado,
          user_id: questao.user_id,
          alternativas: questao.alternativas,
          alternativaCorreta: alternativaCorreta
            ? alternativaCorreta.order
            : "Não encontrada",
        };

        return questaoData;
      });

      this.setState({
        questoesAll: questoesAll,
      });
    } catch (error) {}
  }

  async getData(id) {
    const { match } = this.props;
    const { expandedModulo: moduloId } = this.state;
    const treinamentoId = match.params.id;

    localStorage.setItem(`ultimoConteudoId_${treinamentoId}_${moduloId}`, id);
    this.setState({ loading: true, totalDeAcertos: 0 });

    try {
      await Promise.all([this.getConteudo(id), this.getQuestao(id)]);
      const { questoesAll } = this.state;

      const questoesRespondidas =
        JSON.parse(localStorage.getItem(`questoesRespondidas`)) || {};
      const questoesRespondidasModulo =
        (questoesRespondidas[treinamentoId] &&
          questoesRespondidas[treinamentoId][this.state.selectedContentId]) ||
        {};

      let totalDeAcertos =
        parseInt(
          localStorage.getItem(
            `totalDeAcertos_${this.state.selectedContentId}`
          ),
          10
        ) || 0;
      totalDeAcertos = Math.min(totalDeAcertos, questoesAll.length);

      const permissaoProximoConteudo =
        questoesAll.length > 0 &&
        Object.keys(questoesRespondidasModulo).length === questoesAll.length &&
        Object.values(questoesRespondidasModulo).every((q) => q.correta);

      this.setState(
        (prevState) => ({
          selectedContentId: id,
          totalDeAcertos: totalDeAcertos,
          permissaoProximoConteudo: permissaoProximoConteudo,
          conteudosConcluidos: {
            ...prevState.conteudosConcluidos,
            [treinamentoId]: {
              ...prevState.conteudosConcluidos[treinamentoId],
              [moduloId]: {
                ...prevState.conteudosConcluidos[treinamentoId]?.[moduloId],
                [id]: permissaoProximoConteudo || questoesAll.length === 0,
              },
            },
          },
          conteudosModulosConcluidos: {
            ...prevState.conteudosModulosConcluidos,
            [moduloId]: {
              ...prevState.conteudosModulosConcluidos[moduloId],
              [id]: permissaoProximoConteudo || questoesAll.length === 0,
            },
          },
        }),
        this.verificarModuloCompleto
      );

      localStorage.setItem(
        "modulosConcluidos",
        JSON.stringify(this.state.modulosConcluidos)
      );
      localStorage.setItem(
        "conteudosModulosConcluidos",
        JSON.stringify(this.state.conteudosModulosConcluidos)
      );
    } catch (error) {
    } finally {
      this.setState({ loading: false });
    }
  }

  verificarModuloCompleto() {
    const {
      conteudosConcluidos,
      modulosConcluidos,
      conteudos,
      modulos,
      gerarCertificado,
    } = this.state;
    const { match } = this.props;
    const treinamentoId = match.params.id;
    const moduloId = this.state.expandedModulo;

    const conteudosConcluidosModulo =
      conteudosConcluidos[treinamentoId]?.[moduloId];
    const todosConteudosConcluidos =
      conteudosConcluidosModulo &&
      Object.keys(conteudosConcluidosModulo).length ===
        conteudos[moduloId].length &&
      Object.values(conteudosConcluidosModulo).every((v) => v === true);

    const todosModulosConcluidos =
      todosConteudosConcluidos &&
      Object.keys(conteudosConcluidos[treinamentoId]).length ===
        modulos.length &&
      Object.values(conteudosConcluidos[treinamentoId]).every((modulo) =>
        Object.values(modulo).every((c) => c === true)
      );

    this.setState({
      modulosConcluidos: {
        ...modulosConcluidos,
        [moduloId]: todosConteudosConcluidos,
      },
      gerarCertificado: {
        ...gerarCertificado,
        [treinamentoId]: todosModulosConcluidos,
      },
    });

    localStorage.setItem(
      "quantidadeModulosConcluidosUpdated",
      JSON.stringify(this.state.modulosConcluidos)
    );
  }

  toggleExpand = async (moduloId) => {
    const treinamentoId = this.props.match.params.id;

    this.setState((prevState) => ({
      expandedModulo: prevState.expandedModulo === moduloId ? null : moduloId,
    }));

    if (
      this.state.expandedModulo !== moduloId &&
      !this.state.conteudos[moduloId]
    ) {
      await this.getModulo(moduloId);
      localStorage.setItem(`ultimoModuloId_${treinamentoId}`, moduloId);
    }
  };

  handleResponderPergunta = (questao) => {
    const respostaUsuario =
      this.state.questoesRespondidas?.[this.props.match.params.id]?.[
        this.state.selectedContentId
      ]?.[questao.id]?.resposta || null;

    const updatedModalVisualizacaoTrue = {
      ...this.state.modalVisualizacao,
      [this.props.match.params.id]: {
        ...this.state.modalVisualizacao[this.props.match.params.id],
        [this.state.selectedContentId]: {
          ...this.state.modalVisualizacao[this.props.match.params.id]?.[
            this.state.selectedContentId
          ],
          [questao?.id]: true,
        },
      },
    };

    const updatedModalVisualizacaoFalse = {
      ...this.state.modalVisualizacao,
      [this.props.match.params.id]: {
        ...this.state.modalVisualizacao[this.props.match.params.id],
        [this.state.selectedContentId]: {
          ...this.state.modalVisualizacao[this.props.match.params.id]?.[
            this.state.selectedContentId
          ],
          [questao?.id]: false,
        },
      },
    };

    if (respostaUsuario) {
      this.setState({
        currentQuestion: questao,
        respostaCorreta: questao.alternativaCorreta,
        showModal: true,
        modalVisualizacao: updatedModalVisualizacaoTrue,
        respostaUsuario,
      });
    } else {
      this.setState({
        currentQuestion: questao,
        respostaCorreta: questao.alternativaCorreta,
        showModal: true,
        modalVisualizacao: updatedModalVisualizacaoFalse,
        respostaUsuario: null,
        respostaCorretaMsg: null,
        respostaIncorretaMsg: null,
      });
    }
  };

  handleSelecionarAlternativa = (alternativa) => {
    this.setState({
      respostaUsuario: alternativa.order,
    });
  };

  handleSubmitModal = (currentQuestionId) => {
    const {
      respostaCorreta,
      respostaUsuario,
      currentQuestion,
      selectedContentId,
    } = this.state;

    const treinamentoId = this.props.match.params.id;

    const updatedQuestoesRespondidas = {
      ...this.state.questoesRespondidas,
      [treinamentoId]: {
        ...this.state.questoesRespondidas[treinamentoId],
        [selectedContentId]: {
          ...this.state.questoesRespondidas[treinamentoId]?.[selectedContentId],
          [currentQuestionId]: {
            correta: respostaUsuario === respostaCorreta,
            resposta: respostaUsuario,
          },
        },
      },
    };

    const updatedModalVisualizacao = {
      ...this.state.modalVisualizacao,
      [treinamentoId]: {
        ...this.state.modalVisualizacao[treinamentoId],
        [selectedContentId]: {
          ...this.state.modalVisualizacao[treinamentoId]?.[selectedContentId],
          [currentQuestionId]: true,
        },
      },
    };

    localStorage.setItem(
      `questoesRespondidas`,
      JSON.stringify(updatedQuestoesRespondidas)
    );

    localStorage.setItem(
      `modalVisualizacao`,
      JSON.stringify(updatedModalVisualizacao)
    );

    this.setState({
      questoesRespondidas: updatedQuestoesRespondidas,
      modalVisualizacao: updatedModalVisualizacao,
      lastCorrectQuestionId:
        respostaUsuario === respostaCorreta
          ? currentQuestionId
          : this.state.lastCorrectQuestionId,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      showAnswerModal: false,
      respostaCorretaMsg: null,
    });
  };

  handleAlterar = () => {
    const { formularioEnviado, selectedContentId } = this.state;
    const id = this.props.match.params.id;

    if (!formularioEnviado[id] || !formularioEnviado[id][selectedContentId]) {
      this.setState({
        modalVisualizacao: false,
        respostaCorretaMsg: null,
      });
    }
  };

  isConteudoConcluido = (conteudoId) => {
    return (
      this.state.conteudosConcluidos[this.props.match.params.id]?.[
        this.state.expandedModulo
      ]?.[conteudoId] || false
    );
  };

  resetConteudoConcluido = (conteudoId) => {
    const updatedModalVisualizacao = { ...this.state.modalVisualizacao };
    const id = this.props.match.params.id;

    const selectedContent =
      updatedModalVisualizacao[id]?.[this.state.selectedContentId];

    if (selectedContent) {
      Object.keys(selectedContent).forEach((key) => {
        updatedModalVisualizacao[id][this.state.selectedContentId][key] = false;
      });
    }

    localStorage.removeItem(
      `conteudosConcluidos_${this.props.match.params.id}${this.state.expandedModulo}${conteudoId}`
    );

    this.setState(
      (prevState) => {
        const conteudosModulosConcluidos = {
          ...prevState.conteudosModulosConcluidos,
        };

        Object.keys(conteudosModulosConcluidos).forEach((moduleId) => {
          if (conteudosModulosConcluidos[moduleId][conteudoId] !== undefined) {
            conteudosModulosConcluidos[moduleId][conteudoId] = false;
          }
        });

        const updatedQuestoesRespondidas = {
          ...prevState.questoesRespondidas,
          [this.props.match.params.id]: {
            ...prevState.questoesRespondidas[this.props.match.params.id],
            [this.state.selectedContentId]: {
              ...prevState.questoesRespondidas[this.props.match.params.id][
                this.state.selectedContentId
              ],
            },
          },
        };

        Object.keys(
          updatedQuestoesRespondidas[this.props.match.params.id][
            this.state.selectedContentId
          ]
        ).forEach((key) => {
          updatedQuestoesRespondidas[this.props.match.params.id][
            this.state.selectedContentId
          ][key] = false;
        });

        return {
          modalVisualizacao: updatedModalVisualizacao,
          totalDeAcertos: 0,
          conteudosConcluidos: {
            ...prevState.conteudosConcluidos,
            [this.props.match.params.id]: {
              ...prevState.conteudosConcluidos[this.props.match.params.id],
              [this.state.expandedModulo]: {
                ...prevState.conteudosConcluidos[this.props.match.params.id]?.[
                  this.state.expandedModulo
                ],
                [this.state.selectedContentId]: false,
              },
            },
          },
          conteudosModulosConcluidos: conteudosModulosConcluidos,
          formularioEnviado: {
            ...prevState.formularioEnviado,
            [this.props.match.params.id]: {
              ...prevState.formularioEnviado[this.props.match.params.id],
              [conteudoId]: false,
            },
          },
          quantidadeModulosConcluidosUpdated: {
            ...prevState.quantidadeModulosConcluidosUpdated,
            [this.state.expandedModulo]: false,
          },
          questoesRespondidas: updatedQuestoesRespondidas,
          permissaoProximoConteudo: false,
          showAnswerModal: false,
          modulosConcluidos: {
            ...this.state.modulosConcluidos,
            [this.state.expandedModulo]: false,
          },
          gerarCertificado: {
            ...prevState.gerarCertificado,
            [this.props.match.params.id]: false,
          },
        };
      },
      () => {}
    );
  };

  handleVideoEnded = () => {
    this.setState({ videoWatched: true });
  };

  saveWatchedTime = () => {
    if (this.videoRef.current) {
      const watchedTime = this.videoRef.current.currentTime;
      localStorage.setItem(
        `watchedTime_${this.state.selectedContentId}`,
        watchedTime.toString()
      );
    }
  };

  handleBack = () => {
    this.setState({ redirect: true });
    this.saveTreinamentoStatusToLocalStorage();
  };

  saveTreinamentoStatusToLocalStorage = () => {
    const { conteudosConcluidos } = this.state;
    const id = this.props.match.params.id;

    if (
      Object.keys(conteudosConcluidos[id]).length ===
        this.state.modulos.length &&
      Object.values(conteudosConcluidos[id]).every((modulo) =>
        Object.values(modulo).every((conteudo) => conteudo === true)
      )
    ) {
      localStorage.setItem(`treinamentoConcluido_${id}`, "true");
      localStorage.removeItem(`treinamentoEmAndamento_${id}`);
      localStorage.removeItem(`treinamentoNovo_${id}`);
    } else if (
      Object.values(conteudosConcluidos[id]).every((modulo) =>
        Object.values(modulo).every((conteudo) => conteudo === false)
      )
    ) {
      localStorage.setItem(`treinamentoNovo_${id}`, "true");
      localStorage.removeItem(`treinamentoEmAndamento_${id}`);
      localStorage.removeItem(`treinamentoConcluido_${id}`);
    } else {
      localStorage.setItem(`treinamentoEmAndamento_${id}`, "true");
      localStorage.removeItem(`treinamentoNovo_${id}`);
      localStorage.removeItem(`treinamentoConcluido_${id}`);
    }
  };

  handleVisualizarRespostas = () => {
    this.setState({
      showAnswerModal: true,
    });
  };

  handleAbrirConfirmacao = () => {
    const {
      respostaCorreta,
      respostaUsuario,
      currentQuestion,
      questoesAll,
      selectedContentId,
      questoesRespondidas,
    } = this.state;

    const id = this.props.match.params.id;

    const novaQuestaoRespondida = {
      ...questoesRespondidas,
      [id]: {
        ...questoesRespondidas[id],
        [selectedContentId]: {
          ...questoesRespondidas[id][selectedContentId],
          [currentQuestion?.id]: {
            correta: respostaUsuario === respostaCorreta,
            resposta: respostaUsuario,
          },
        },
      },
    };
    let novoTotalDeAcertos = Object.values(
      novaQuestaoRespondida[id][selectedContentId]
    ).reduce((total, questao) => {
      return total + (questao.correta ? 1 : 0);
    }, 0);

    if (novoTotalDeAcertos > questoesAll.length) {
      novoTotalDeAcertos = questoesAll.length;
    }

    localStorage.setItem(
      `totalDeAcertos_${selectedContentId}`,
      novoTotalDeAcertos
    );

    this.setState((prevState) => ({
      formularioEnviado: {
        ...prevState.formularioEnviado,
        [id]: {
          ...prevState.formularioEnviado[id],
          [selectedContentId]: true,
        },
      },
      totalDeAcertos: novoTotalDeAcertos,
    }));
  };

  handleConcluido = () => {
    const {
      respostaCorreta,
      respostaUsuario,
      currentQuestion,
      questoesAll,
      selectedContentId,
      questoesRespondidas,
      expandedModulo,
      conteudos,
    } = this.state;

    const id = this.props.match.params.id;
    const isRespostaCorreta = respostaUsuario === respostaCorreta;

    const novaQuestaoRespondida = {
      ...questoesRespondidas,
      [id]: {
        ...questoesRespondidas[id],
        [selectedContentId]: {
          ...questoesRespondidas[id]?.[selectedContentId],
          [currentQuestion.id]: {
            correta: isRespostaCorreta,
            resposta: respostaUsuario,
          },
        },
      },
    };

    const novoTotalDeAcertos = Math.min(
      Object.values(novaQuestaoRespondida[id][selectedContentId]).reduce(
        (total, questao) => total + (questao.correta ? 1 : 0),
        0
      ),
      questoesAll.length
    );

    const permissaoProximoConteudo =
      Object.keys(novaQuestaoRespondida[id][selectedContentId]).length ===
        questoesAll.length &&
      Object.values(novaQuestaoRespondida[id][selectedContentId]).every(
        (q) => q.correta
      );

    localStorage.setItem(
      `totalDeAcertos_${selectedContentId}`,
      novoTotalDeAcertos
    );

    this.setState(
      (prevState) => ({
        formularioEnviado: {
          ...prevState.formularioEnviado,
          [id]: {
            ...prevState.formularioEnviado[id],
            [selectedContentId]: true,
          },
        },
        conteudosConcluidos: {
          ...prevState.conteudosConcluidos,
          [id]: {
            ...prevState.conteudosConcluidos[id],
            [expandedModulo]: {
              ...prevState.conteudosConcluidos[id]?.[expandedModulo],
              [selectedContentId]: permissaoProximoConteudo,
            },
          },
        },
        totalDeAcertos: novoTotalDeAcertos,
        permissaoProximoConteudo,
        showAnswerModal: false,
        conteudosModulosConcluidos: {
          ...prevState.conteudosModulosConcluidos,
          [expandedModulo]: permissaoProximoConteudo,
        },
      }),
      () => {
        const conteudosConcluidosAtualizados =
          this.state.conteudosConcluidos[id][expandedModulo];
        const conteudosDoModulo = conteudos[expandedModulo];
        const moduloCompleto =
          Object.keys(conteudosConcluidosAtualizados).length ===
            conteudosDoModulo.length &&
          Object.values(conteudosConcluidosAtualizados).every(
            (value) => value === true
          );

        this.setState((prevState) => ({
          modulosConcluidos: {
            ...prevState.modulosConcluidos,
            [expandedModulo]: moduloCompleto,
          },
          quantidadeModulosConcluidosUpdated: {
            ...prevState.quantidadeModulosConcluidosUpdated,
            [expandedModulo]: moduloCompleto,
          },
        }));

        if (moduloCompleto) {
          localStorage.setItem(
            "quantidadeModulosConcluidosUpdated",
            JSON.stringify(this.state.quantidadeModulosConcluidosUpdated)
          );
        }

        this.verificarModuloCompleto();
      }
    );
  };

  render() {
    const {
      nome,
      modulos,
      loading,
      expandedModulo,
      conteudos,
      questoesAll,
      file,
      videoFileName,
      thumbnail,
      watchedTime,
      redirect,
      selectedContentId,
      loadingModulo,
      showModal,
      currentQuestion,
      respostaCorretaMsg,
      respostaCorreta,
      questoesRespondidas,
      modalVisualizacao,
      permissaoProximoConteudo,
      respostaUsuario,
      videoWatched,
      totalDeAcertos,
      showAnswerModal,
      formularioEnviado,
      conteudosConcluidos,
      gerarCertificado,
      conteudosModulosConcluidos,
      modulosConcluidos,
    } = this.state;

    const isPdfFile = file && file.endsWith(".pdf");

    if (redirect) {
      return <Redirect to="/treinamentos" />;
    }

    return (
      <div className="container content-page">
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <>
            <button
              className="btn btn-secondary float-end"
              onClick={this.handleBack}
            >
              Voltar
            </button>
            <h1 className="text-center mb-4">{nome}</h1>
            <button
              className="btn btn-warning mb-4"
              onClick={() => this.resetConteudoConcluido(selectedContentId)}
            >
              Desmarcar como Concluído
            </button>
            <div className="row">
              <div className="col-md-8">
                <VideoPlayer
                  file={file}
                  videoFileName={videoFileName}
                  thumbnail={thumbnail}
                  watchedTime={watchedTime}
                  handleVideoEnded={this.handleVideoEnded}
                  videoRef={this.videoRef}
                />

                <QuestionsTable
                  questoesAll={questoesAll}
                  isPdfFile={isPdfFile}
                  handleResponderPergunta={this.handleResponderPergunta}
                  questoesRespondidas={questoesRespondidas}
                  videoWatched={videoWatched}
                  permissaoProximoConteudo={permissaoProximoConteudo}
                  handleVisualizarRespostas={this.handleVisualizarRespostas}
                  formularioEnviado={formularioEnviado}
                  selectedContentId={selectedContentId}
                  treinamentoId={this.props.match.params.id}
                />
                <AnswerModal
                  showAnswerModal={showAnswerModal}
                  handleCloseModal={this.handleCloseModal}
                  totalDeAcertos={totalDeAcertos}
                  questoesAll={questoesAll}
                  respostaCorreta={respostaCorreta}
                  resetConteudoConcluido={this.resetConteudoConcluido}
                  handleAbrirConfirmacao={this.handleAbrirConfirmacao}
                  formularioEnviado={formularioEnviado}
                  selectedContentId={selectedContentId}
                  treinamentoId={this.props.match.params.id}
                  moduloId={this.state.expandedModulo}
                  questoesRespondidas={questoesRespondidas}
                  handleConcluido={this.handleConcluido}
                  conteudosConcluidos={conteudosConcluidos}
                  permissaoProximoConteudo={permissaoProximoConteudo}
                />
              </div>
              <div className="col-md-4">
                <ModuleList
                  modulos={modulos}
                  expandedModulo={expandedModulo}
                  toggleExpand={this.toggleExpand}
                  conteudos={conteudos}
                  selectedContentId={selectedContentId}
                  getData={this.getData}
                  isConteudoConcluido={this.isConteudoConcluido}
                  loadingModulo={loadingModulo}
                  permissaoProximoConteudo={permissaoProximoConteudo}
                  gerarCertificado={gerarCertificado}
                  idTreinamento={this.props.match.params.id}
                  nomeTreinamento={nome}
                  token={this.props.token}
                  conteudosModulosConcluidos={conteudosModulosConcluidos}
                  modulosConcluidos={modulosConcluidos}
                />
              </div>
            </div>
          </>
        )}
        <QuestionModal
          showModal={showModal}
          AnswerModal
          handleCloseModal={this.handleCloseModal}
          currentQuestionId={currentQuestion}
          questoes={questoesAll}
          questoesRespondidas={questoesRespondidas}
          respostaCorretaMsg={respostaCorretaMsg}
          respostaCorreta={respostaCorreta}
          respostaUsuario={respostaUsuario}
          handleSelecionarAlternativa={this.handleSelecionarAlternativa}
          handleSubmitModal={this.handleSubmitModal}
          handleAlterar={this.handleAlterar}
          modalVisualizacao={modalVisualizacao}
          formularioEnviado={formularioEnviado}
          selectedContentId={selectedContentId}
          treinamentoId={this.props.match.params.id}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.AppReducer.token,
  faculdade: state.AppReducer.faculdade,
});

export default connect(mapStateToProps, { logout })(ShowTreinamentos);
