// Libraries imports
import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-loading-skeleton/dist/skeleton.css";
import { Alert } from "react-bootstrap";

// Components imports
import LoadingSkeleton from "./Components/LoadingSkeleton";
import NextArrow from "./Components/NextArrow";

// Utils imports
import { get, post } from "../../../services/api";
import { logout } from "../../../actions/AppActions";
import { formatDate } from "../../../utils/formatDate";

const Treinamentos = ({ token }) => {
  const [treinamentos, setTreinamentos] = useState([]);
  const [treinamentosNovos, setTreinamentosNovos] = useState([]);
  const [treinamentosEmAndamento, setTreinamentosEmAndamento] = useState([]);
  const [treinamentosConcluidos, setTreinamentosConcluidos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    const allTrainings = [
      ...(treinamentosConcluidos || []),
      ...(treinamentosEmAndamento || []),
      ...(treinamentosNovos || []),
    ];

    const handleTraining = async (treinamento, isCompleted) => {
      const id = treinamento.id.toString();
      await handleCompleteTraining(id, isCompleted);
    };

    Promise.all(
      allTrainings.map(async (treinamento) => {
        const isCompleted = treinamentosConcluidos.some(
          (t) => t.id === treinamento.id
        )
          ? !!localStorage.getItem(
              `treinamentoConcluido_${treinamento.id.toString()}`
            )
          : false;
        await handleTraining(treinamento, isCompleted);
      })
    );
  }, [treinamentosConcluidos, treinamentosEmAndamento, treinamentosNovos]);

  const getUser = async () => {
    try {
      const data = await get(`api/fiscal/get_user/`, token);
      const user = data.user;
      setUserId(user.id);
      getTreinamentos(user.id);
    } catch (error) {}
  };

  const getTreinamentos = async (userId) => {
    try {
      const data = await get(`api/fiscal/treinamento/${userId}`, token);

      if (!data || !data.user || !data.user.treinamentos) {
        throw new Error("Invalid user or trainings data");
      }

      const treinamentosNovos = [];
      const treinamentosEmAndamento = [];
      const treinamentosConcluidos = [];
      const currentDate = new Date();

      data.user.treinamentos.forEach((treinamento) => {
        const dataInicial = new Date(treinamento.data_inicial);
        const dataFinal = new Date(treinamento.data_final);

        if (currentDate >= dataInicial && currentDate <= dataFinal) {
          const id = treinamento.id.toString();
          if (localStorage.getItem(`treinamentoConcluido_${id}`)) {
            treinamentosConcluidos.push(treinamento);
          } else if (localStorage.getItem(`treinamentoEmAndamento_${id}`)) {
            treinamentosEmAndamento.push(treinamento);
          } else {
            treinamentosNovos.push(treinamento);
          }
        }
      });

      setTreinamentos(data.user.treinamentos);
      setTreinamentosNovos(treinamentosNovos);
      setTreinamentosEmAndamento(treinamentosEmAndamento);
      setTreinamentosConcluidos(treinamentosConcluidos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setRedirect(true);
  };

  const handleCompleteTraining = async (trainingId, isCompleted) => {
    try {
      const response = await post(
        `api/update_training_status/${userId}`,
        {
          trainings: [
            {
              training_id: trainingId,
              concluido: isCompleted,
            },
          ],
        },
        token
      );
      return response;
    } catch (error) {}
  };

  if (redirect) return <Redirect to="/" />;

  const sliderSettingsNew = {
    dots: true,
    speed: 500,
    infinite: treinamentosNovos.length > 2,
    slidesToShow: 2,
    slidesToScroll: 2,
    className: "my-4",
    nextArrow:
      treinamentosNovos.length > 2 ? <NextArrow direction="next" /> : null,
    prevArrow:
      treinamentosNovos.length > 2 ? <NextArrow direction="prev" /> : null,
  };

  const sliderSettingsInProgress = {
    dots: true,
    speed: 500,
    infinite: treinamentosEmAndamento.length > 2,
    slidesToShow: 2,
    slidesToScroll: 2,
    className: "my-4",
    nextArrow:
      treinamentosEmAndamento.length > 2 ? (
        <NextArrow direction="next" />
      ) : null,
    prevArrow:
      treinamentosEmAndamento.length > 2 ? (
        <NextArrow direction="prev" />
      ) : null,
  };

  const sliderSettingsConcluded = {
    dots: true,
    speed: 500,
    infinite: treinamentosConcluidos.length > 2,
    slidesToShow: 2,
    slidesToScroll: 2,
    className: "my-4",
    nextArrow:
      treinamentosConcluidos.length > 2 ? <NextArrow direction="next" /> : null,
    prevArrow:
      treinamentosConcluidos.length > 2 ? <NextArrow direction="prev" /> : null,
  };

  return (
    <div className="container-fluid py-5 text-center">
      <div className="d-flex justify-content-between mb-4 align-items-center">
        <button className="btn btn-secondary" onClick={handleBack}>
          Voltar
        </button>
        <button className="btn btn-success" onClick={handleBack}>
          Ir para processos
        </button>
      </div>
      <div className="container-fluid py-5 text-center">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h1 className="mx-auto">Treinamentos</h1>
        </div>
      </div>

      <hr className="my-5" />

      <div className="mb-5">
        <h2>Novos</h2>
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <>
            {treinamentosNovos.length === 0 && (
              <Alert variant="warning">Nenhum treinamento disponível</Alert>
            )}
            <Slider {...sliderSettingsNew}>
              {treinamentosNovos.length > 0 &&
                treinamentosNovos.map((treinamento, index) => (
                  <div key={index}>
                    <div className="mr-3">
                      <div className="card h-100" style={{ cursor: "grab" }}>
                        <div className="card-body d-flex flex-column">
                          <h5 className="card-title">{treinamento.nome}</h5>
                          <p className="card-text">{treinamento?.descricao}</p>
                          <p className="card-text">
                            Data Inicial: {formatDate(treinamento.data_inicial)}
                          </p>
                          <p className="card-text">
                            Data Final: {formatDate(treinamento.data_final)}
                          </p>
                          <Link
                            to={`/treinamentos/${treinamento.id}`}
                            className="btn btn-primary mt-auto"
                          >
                            Começar
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </>
        )}
      </div>

      <hr className="my-5" />

      <div className="mb-5">
        <h2>Em andamento</h2>
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <>
            {treinamentosEmAndamento.length === 0 && (
              <Alert variant="warning">
                Nenhum treinamento em andamento disponível
              </Alert>
            )}
            <Slider {...sliderSettingsInProgress}>
              {treinamentosEmAndamento.length > 0 &&
                treinamentosEmAndamento.map((treinamento, index) => (
                  <div key={index}>
                    <div className="mr-3">
                      <div className="card h-100" style={{ cursor: "grab" }}>
                        <div className="card-body d-flex flex-column">
                          <h5 className="card-title">{treinamento.nome}</h5>
                          <p className="card-text">{treinamento.descricao}</p>
                          <p className="card-text">
                            Data Inicial: {formatDate(treinamento.data_inicial)}
                          </p>
                          <p className="card-text">
                            Data Final: {formatDate(treinamento.data_final)}
                          </p>
                          <Link
                            to={`/treinamentos/${treinamento.id}`}
                            className="btn btn-primary mt-auto"
                          >
                            Continuar
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </>
        )}
      </div>

      <hr className="my-5" />

      <div className="mb-5">
        <h2>Finalizados</h2>
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <>
            {treinamentosConcluidos.length === 0 && (
              <Alert variant="warning">
                Nenhum treinamento finalizado disponível
              </Alert>
            )}
            <Slider {...sliderSettingsConcluded}>
              {treinamentosConcluidos.length > 0 &&
                treinamentosConcluidos.map((treinamento, index) => (
                  <div key={index}>
                    <div className="mr-3">
                      <div className="card h-100" style={{ cursor: "grab" }}>
                        <div className="card-body d-flex flex-column">
                          <h5 className="card-title">{treinamento.nome}</h5>
                          <p className="card-text">{treinamento.descricao}</p>
                          <p className="card-text">
                            Data Inicial: {formatDate(treinamento.data_inicial)}
                          </p>
                          <p className="card-text">
                            Data Final: {formatDate(treinamento.data_final)}
                          </p>
                          <Link
                            to={`/treinamentos/${treinamento.id}`}
                            className="btn btn-primary mt-auto"
                          >
                            Ver dados
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.AppReducer.token,
  faculdade: state.AppReducer.faculdade,
});

export default connect(mapStateToProps, { logout })(Treinamentos);